import { Component, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { ThemeService } from './theme.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';
import { environment } from '../environments/environment';

declare function gtag(...args: unknown[]): void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [
    RouterOutlet,
    ToastModule,
    ConfirmDialogModule
  ]
})
export class AppComponent implements OnInit {
  private router = inject(Router);

  constructor(private primengConfig: PrimeNGConfig) {
    inject(ThemeService);

    if (environment.name === 'production') {
      this.router.events.pipe(
        takeUntilDestroyed(),
        filter((event) => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        gtag('config', 'GTM-KSQCSS7Z', {
          page_path: event.urlAfterRedirects
        });
      });
    }
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }
}
